<template>
  <div id="Home">
    <h1 class="Titulo">BIENVENIDOS</h1>

    <div class="cont">

      <button @click="gotoBarragan">
        <img src="../assets/homeIcon_1.png" alt="Laason">
        <div class="column">
          <p>LABORATORIO <br>
            CLINICO BARRAGÁN</p>
          <div class="more">
            <button @click.stop="showmodal2 = !showmodal2" class="cert">
              <p>Ver Certificado</p>
            </button>
            <span>Sistema de Gestión de Calidad
              Certificado por ISO 9001:2015</span>
          </div>
        </div>

        <img src="../assets/BGIMGHOME1.png" alt="" class="bgButtonHover">
      </button>

      <button @click="gotoLaason">
        <img src="../assets/homeIcon_2.png" alt="Laason">
        <div class="column">
          <span>MICROBIOLOGÍA</span>
          <p>Laboratorio <br>
            de Alimentos y Aguas <br>
            de Sonora S.A. de C.V.</p>
          <div class="more">
            <button @click.stop="showmodal = !showmodal" class="cert">
              <p>Ver Certificado</p>
            </button>
            <span>Laboratorio Acreditado <br>
              ISO/IEC 17025:2017</span>
          </div>
        </div>

        <img src="../assets/BGIMGHOME2.png" alt="" class="bgButtonHover">

      </button>

      <button @click="gotoFQ">
        <img src="../assets/homeIcon_2.png" alt="Laason">
        <div class="column">
          <span>FISICOQUÍMICOS</span>
          <p>Laboratorio <br>
            de Alimentos y Aguas <br>
            de Sonora S.A. de C.V.</p>
          <div class="more">
            <button class="cert">
              <p>Ver Certificado</p>
            </button>
            <span>Laboratorio Acreditado <br>
              ISO/IEC 17025:2017</span>
          </div>
        </div>

        <img src="../assets/BGIMGHOME3.png" alt="" class="bgButtonHover">

      </button>

    </div>
    <span class="footer">
      Garmendia N°80, Col.San Benito C.P. 83190 <br> <br>
      Hermosillo, Sonora.
    </span>

    <div v-if="showmodal2 == true" class="modalss">
      <BarraganModal @listenModal="closeModal" />
    </div>
    <!--<h1 class="Titulo2">PRÓXIMAMENTE</h1>-->

    <!-- <div class="C1">
      <a href="/barragan">
        <div class="F1C1">
          <div class="F1C1_C1">
            <img src="../assets/logo1_1.png">
          </div>
          <div class="F1C1_C2">
            <p>LABORATORIO CLÍNICO BARRAGAN</p>


          </div>
          <div class="ver">
            <p>VER MÁS</p>
          </div>
          <p class="LastText">Sistema de Gestión de Calidad
            Certificado por ISO 9001:2015</p>
        </div>
      </a>

      <a class="btncerti2" @click="showmodal2 = !showmodal2">Ver certificado</a>



    </div> -->

    <!-- <div class="C2">
      <a href="/laason">
        <div class="F1C2">
          <div class="F1C2_C1">
            <img src="../assets/logo2_1.png">
          </div>
          <div class="F1C2_C2">
            <p>LABORATORIO</p>
            <p>DE ALIMENTOS Y AGUAS</p>
            <p>DE SONORA S.A. DE C.V.</p>


          </div>
          <div class="ver">
            <p>VER MÁS</p>
          </div>
          <p class="LastText2">Laboratorio Acreditado
            por ISO/IEC 17025:2017</p>
        </div>
        <div class="foo">
          <p>Garmendia N°80, Col.San Benito C.P. 83190</p>
          <p>Hermosillo, Sonora.</p>
        </div>
      </a>

      <a class="btncerti" @click="showmodal = !showmodal">Ver certificado</a>


    </div> -->

    <div v-if="showmodal == true" class="modalss">
      <LaasonModal @listenModal="closeModal" />
    </div>
  </div>

</template>
<script>

import LaasonModal from './modal/LaasonModal.vue';
import BarraganModal from './modal/BarraganModal.vue';


export default {
  name: 'Home',
  data() {
    return {
      showmodal: false,
      showmodal2: false
    }
  },
  components: {
    LaasonModal,
    BarraganModal
  },
  methods: {
    closeModal(value) {
      this.showmodal = value;
      this.showmodal2 = value;
    },
    gotoBarragan() {
      this.$router.push('/barragan');
    },
    gotoLaason() {
      this.$router.push('/laason');
    },
    gotoFQ() {
      window.location.href = 'http://fq.laason.mx';
    },
  },

}
</script>
<style scoped>
.modalss {
  position: fixed;
  z-index: 1;
  padding-top: 8.208333333333334VW;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.btncerti {
  white-space: nowrap;
  height: 0vw !important;
  width: 0vw !important;
  align-items: center;

  background: linear-gradient(85.48deg, #6FBA91 4.15%, #76DFA6 139.07%) !important;
  border-radius: 30px !important;
  color: #fff !important;
  padding: 1.2vw 6.5vw !important;
  font-size: 0.75VW !important;
  text-decoration: none !important;
  position: relative !important;
  top: 2vw !important;
  padding-left: 2.5vw !important;
  margin-top: 9vw;
  margin-left: -34vw;
}

.btncerti2 {
  white-space: nowrap;
  height: 0vw !important;
  width: 0vw !important;
  align-items: center;

  background: linear-gradient(85.48deg, #6FBA91 4.15%, #76DFA6 139.07%) !important;
  border-radius: 30px !important;
  color: #fff !important;
  padding: 1.2vw 6.5vw !important;
  font-size: 0.75VW !important;
  text-decoration: none !important;
  position: relative !important;
  top: 2vw !important;
  padding-left: 2.5vw !important;
  margin-top: 9vw;
  margin-left: -6vw;
}

#Home {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.Titulo {
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 3.59375vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3.333333vw;
  /* 92.754% */
  background: linear-gradient(269.07deg, #6FBA91 -19.33%, #0A4CA1 112.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.Titulo2 {
  display: none;
}

.C1 {
  width: 50%;
  height: 48vw;
  display: flex;
  justify-content: right;
  padding-right: 5vw;
  cursor: pointer;
  background-image: url('../assets/whiteblock.png');
  background-size: 50vw 48vw;
  transition: 1s;
}

.C1:hover {
  background-color: rgb(230, 228, 228);
  background-image: url('../assets/Rectangle_3.png');
  background-repeat: no-repeat;
  background-size: 50vw 48vw;
}

.C1 a {
  align-self: center;
  height: 100%;
  width: 100%;
  display: flex;
  padding-left: 29vw;
}

.F1C1 {
  display: flex;
  align-items: center;
  float: right;
}

.F1C1_C1 img {
  width: 8.333333333333332VW;
  height: auto;
  background: transparent;
}

.F1C1_C2 {
  width: 11vw;
  padding-top: 3vw;
  padding-left: 2vw;
}

.F1C1_C2 p {
  font-size: 1.0416666666666665VW;
  text-decoration: underline;
  color: #0A4CA1;
  font-weight: 700;
  background: transparent;
}



.C2 {
  width: 50%;
  height: 48vw;
  display: flex;
  justify-content: left;
  padding-left: 5vw;
  background-image: url('../assets/whiteblock.png');
  background-size: 50vw 48vw;
  transition: 1s;
  cursor: pointer;
}

.C2:hover {
  background-color: rgb(230, 228, 228);
  background-image: url('../assets/Rectangle_4.png');
  background-repeat: no-repeat;
  background-size: 49vw 48vw;
}

.C2 a {
  align-self: center;
  height: 100%;
  width: 100%;
  display: flex;
}

.F1C2 {
  display: flex;
  align-items: center;
  float: left;
}

.F1C2_C1 img {
  width: 8.333333333333332VW;
  height: auto;
  background: transparent;
}

.F1C2_C2 {
  width: fit-content;
  padding-top: 2.5vw;
  padding-left: 2vw;

}

.F1C2_C2 p {
  padding-left: 1vw;
  font-size: 1.0416666666666665VW;
  text-decoration: underline;
  margin: 0;
  color: #0A4CA1;
  font-weight: 700;
  border: 0px solid #ccc;
  border-radius: 0px;
  background: transparent;
}

.foo {
  margin-top: 42vw;
  margin-left: -12vw;
  position: absolute;
  text-align: center;
  font-size: 0.7291666666666666VW;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}

.ver {
  display: none;
}

p.LastText {
  width: 10vw;
  color: #565656;
  font-size: 0.7291666666666666VW;
  font-weight: 400;
  margin-top: 19.5vw;
  margin-left: -9.5vw;
}

p.LastText2 {
  width: 8vw;
  color: #565656;
  font-size: 0.7291666666666666VW;
  font-weight: 400;
  margin-top: 19.5vw;
  margin-left: -11vw;
}

.cont {
  display: flex;
  flex-direction: row;
  gap: 3.125vw;
}

.cont>button {
  display: flex;
  flex-direction: row;
  gap: 1.666667vw;
  border: 0;
  background-color: transparent;
  align-items: flex-end;
}

.cont>button img {
  width: 6.875vw;
  height: 8.645833vw;
  margin-bottom: 3.854167vw;
}

.column {
  display: flex;
  flex-direction: column;
  gap: .885417vw;
}

.column>span {
  color: #0A4CA1;
  font-family: "Source Sans Pro";
  font-size: .9375vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5625vw;
  /* 166.667% */
  text-align: left;
}

.column>p {
  color: #0A4CA1;
  font-family: "Source Sans Pro";
  font-size: 1.041667vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5625vw;
  /* 150% */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  text-transform: uppercase;
  text-align: left;
  margin: 0;
}

.more {
  display: flex;
  flex-direction: column;
  gap: .885417vw;
}

.more button {
  display: flex;
  width: 8.697917vw;
  height: 2.083333vw;
  justify-content: center;
  align-items: center;
  gap: .520833vw;
  border-radius: 5.208333vw;
  background: #73BE95;
  border: 0;
}

.more button p {
  color: #FFF;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: .625vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.more span {
  color: #565656;
  font-family: "Source Sans Pro";
  font-size: .78125vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  width: 10.104167vw;
  text-align: left;
}

.footer {
  color: #0A4CA1;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: .78125vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3.208333vw;
}

.bgButtonHover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: -2;
  opacity: 0;
  object-fit: cover;
  transition: opacity 1s ease;
  filter: blur(.260417vw);
}

.cont>button:hover .bgButtonHover {
  opacity: 0.8;
  transform: scale(1.0);
  filter: blur(0vw);
}

@media (max-width: 768px) {


  .btncerti {
    display: none !important;
  }

  .btncerti2 {
    display: none !important;
  }

  .ver {
    display: block;
    color: #6FBA91;
    font-size: 3.3816425120772946VW;
    text-decoration: underline;
    font-weight: 700;
    margin-top: 10vw;
    margin-left: 20vw;
  }

  .cont {
    flex-direction: column !important;
  }

  .Titulo {
    font-size: 8.695652vw !important;
    line-height: 9.661836vw !important;
    margin-top: 16.183575vw;
  }

  .Titulo2 {
    display: block;
    font-size: 7.246376811594203VW;
    font-weight: 800;
    line-height: 15.5VW;
    margin-left: 25vw;
    position: absolute;
    background: linear-gradient(269.07deg, #6FBA91 -19.33%, #0A4CA1 112.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .C1 {
    width: 21vw;
    height: 100vw;
    display: flex;
    justify-content: right;
    padding-right: 0vw;
    background-image: none;
  }

  .C1:hover {
    background-image: none;
    background-color: white;
  }

  .F1C1 {
    display: block;
    align-items: center;
    float: right;
    margin-top: 18vw;
  }

  .F1C1_C1 img {
    margin-left: 14vw;
    width: 28.985507246376812VW;
    height: auto;
    background: transparent;
  }

  .F1C1_C2 {
    width: 0vw;
    padding-top: 5vw;
    padding-left: 2vw;
  }

  .F1C1_C2 p {
    font-size: 5.314009661835748VW;
    text-decoration: underline;
    color: #0A4CA1;
    font-weight: 700;
    background: transparent;
    width: 54vw;
    text-align: center;
  }


  .C2 {
    border-top: .052083vw solid gray;
    padding-top: 5vw;
    width: fit-content;
    height: 90vw;
    display: flex;
    justify-content: left;
    padding-left: 0vw;
    background-image: none;
    margin-left: 20vw;
  }

  .C2:hover {
    background-image: none;
    background-color: white;
  }

  .F1C2 {
    display: block;
    align-items: center;
    float: left;
  }

  .F1C2_C2 {
    width: fit-content;
    padding-top: 5vw;
    padding-left: 0vw;
    text-align: center;
  }

  .F1C2_C1 img {
    margin-left: 14vw;
    width: 28.985507246376812VW;
    height: auto;
    background: transparent;
  }

  .F1C2_C2 p {
    padding-left: 1vw;
    font-size: 5.314009661835748VW;
    text-decoration: underline;
    margin: 0;
    color: #0A4CA1;
    font-weight: 700;
    border: 0vw solid #ccc;
    border-radius: 0vw;
    background: transparent;
  }

  .foo {
    display: none;
  }


  p.LastText {
    display: none;
  }

  p.LastText2 {
    display: none;
  }

  .cont {
    gap: 14.492754vw;
  }

  .cont>button {
    flex-direction: column;
    align-items: center;
    gap: 4.10628vw;
  }

  .cont>button img {
    margin-bottom: auto;
    width: 35.024155vw;
    height: 35.024155vw;
    object-fit: cover;
  }

  .column {
    gap: 4.10628vw;
  }

  .column>p {
    text-align: center;
    font-size: 4.830918vw;
    font-style: normal;
    font-weight: 700;
    line-height: 7.246377vw;
    /* 150% */
  }

  .more {
    align-items: center;
    gap: 4.10628vw;
  }

  .more button {
    width: 40.338164vw;
    height: 9.661836vw;
  }

  .more button p {
    font-size: 2.898551vw;
  }

  .more span {
    text-align: center;
    font-size: 3.623188vw;
    width: 46.859903vw;
  }

  .column>span {
    text-align: center;
  }

  #Home {
    overflow: scroll;
    gap: 16.183575vw;
  }

  .footer {
    font-size: 2.88125vw;
  }
}
</style>
